import { object, nativeWindow, globalShortcut, webContents, platform } from '@todesktop/client-core';
// const winIdFromToDesktopBuilder = 'Q9ey6fScatDnLb3uA5NL6';
// const winRef = await object.retrieve({ id: winIdFromToDesktopBuilder });

window.onload = async () => {  // Marked as async
  if (platform.todesktop.isDesktopApp()){
    const winIdFromToDesktopBuilder = 'Q9ey6fScatDnLb3uA5NL6';
    const mainWindowRef = await object.retrieve({ id: '8us0l94RHzubERahG4NQY' });
    const winRef = await object.retrieve({ id: winIdFromToDesktopBuilder });

    globalShortcut.register(window.document.documentElement.dataset.globalCaptureHotkey, async () => {  // Marked as async
      if(await nativeWindow.isVisible({ref: winRef})) {
        await nativeWindow.hide({ref: winRef});
        // make the url change to the new url
      } else {
        await webContents.loadURL(
          { ref: await nativeWindow.getWebContents({ ref: winRef }) },
          'https://hyperaide.com/desktop/capture'
        );
        await nativeWindow.show({ref: winRef});
      }
    });


    await object.on('go-to-settings', async () => {
      await webContents.loadURL(
        { ref: await nativeWindow.getWebContents({ ref: mainWindowRef }) },
        'https://hyperaide.com/settings'
      );
    });
  }
}

